<template>
    <div class="titleTemplate_container">
        <div class="titleTemplate_title global_text_content"  :style="getStyle()">
            <el-input
                v-model="data.title"
                v-input-data="data.title"
                placeholder="点此输入标题"
                @input="changeTitle"
                type="textarea"
                ref="title"
            />
            <div class="global_text_count">{{getCount()}}</div>
        </div>
        <div class="titleTemplate_content">
            <CustomImage :src="data.content" />
        </div>
    </div>
</template>
<script>
import CustomImage  from '@/components/unit/customImage'
import {ElInput} from 'element-plus';
export default {
    name:"titleTemplate",
    components:{
        CustomImage,
        ElInput
    },
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            data:{
                title:"",
                content:""
            },
            reset:0, // 0 默认值，1 被重置 2 被修改
            map:{ 
                background_color:"background",
                color:"color"
            },
            fields:["background_color","color"],
            keys:['title','content']
            
        }
    },    
    created(){
        this.initData()
    },
    mounted(){
        this.$nextTick(()=>{
            this.$refs.title.focus()
        })
    },
    methods:{
        getStyle(){
            let item =  this.pageItem.content?.[0] || {}
            let style = ''
            this.fields.forEach(key=>{
                if(item[key]){
                    style = `${style}${this.map[key]}:#${item[key]};`
                }
            })
            return style;
        },
        initData(dataKey){
            dataKey = dataKey || "class_content"
            this.keys.forEach((key,index)=>{
                this.data[key] =  this.pageItem.content[index][dataKey]
            })
        },
        getCount(){
            let data = this.$tools.dealWithText(this.data.title,this.getCfg('title','template_max'))
            return `${data.count}/${this.getCfg('title','template_max')}`
        },
        clearInfo(){
            this.data.title = ""
            this.reset = 2;
        },
        doEdit(){
        },
        changeTitle(){
            this.reset = 2;
            this.data.title = this.$tools.dealWithText(this.data.title,this.getCfg('title','template_max')).content
        },
        restore(){
            this.reset = 1
            this.initData('default_content')
        },
        getCfg(key,attr){
            let index = this.keys.indexOf(key)
            return this.pageItem.content[index][attr]
        },
        formatData(){
            let data = []
            let code = 200
            for(let key in this.data){
                data.push(this.data[key])
            }
            data = data.map((item,index)=>{
                let update_status = 0;
                if(this.reset != 1){
                    update_status = this.reset == 0 ? this.pageItem.content[0].update_status : 1
                }
                return {
                    class_content:item,
                    update_status,
                    id:this.pageItem.content[index].id
                }
            })
            if(this.data.title == ''){
                this.$tips.error({text:"标题必填"})
                code =  400
            }
            return {code,data}
        },
        getData(callback){
            let data = this.formatData()
            callback(data)
        }
    }
}
</script>
<style>
.titleTemplate_container{
    height:100%;
    width:100%;
}
.titleTemplate_title{
    /* background: linear-gradient(#9e004b,#a0044e,#cc6f9c); */
    height:16.4vw;
    width:100%;
    position: absolute;
    left:0;
    top:0;
    z-index:702;
}
.titleTemplate_content{
    width:100%;
    height:100%;
}
.titleTemplate_title textarea{
    background:transparent;
    border:none;
    color:#fff;
    font-size:3.6vw;
    line-height: 1.3;
}
.titleTemplate_title textarea::placeholder{
    color:#fff;
}
.titleTemplate_title  .el-input__count{
    background:transparent;
    color:#fff !important;
}
</style>